/** @jsx jsx */
import { Container, jsx, Divider } from 'theme-ui';
import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyHelmet } from '../utils/helmet';
import { DefaultLayout } from '../components/DefaultLayout';
import RenderWorkItem from '../components/RenderWorkItem';
import { useGtag } from '../utils/utils';

interface WorkItemObj {
  node: {
    html: HTMLElement
    excerpt: string
    frontmatter: {
      title: string
      category: string
      image?: unknown
      slug: string
      published: boolean
      projectTitle: string
      projectClient: string
      projectAgency: string
      portfolioCategories: any
    }
  }
}

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    allMarkdownRemark: {
      edges: WorkItemObj[]
    }
  }
}


const WorksPage: React.FC<PageTemplateProps> = ({ data }) => {
  return (
    <DefaultLayout>
      <GatsbyHelmet
        title={`Work - ` + data.site.siteMetadata.title}
        description={data.site.siteMetadata.description}/>
      <h6 sx={{ variant: 'link.breadcrumbHeading' }}>
        work.
        <Divider sx={{ variant: 'link.breadcrumbDivider' }}/>
      </h6>
      <Container sx={{ variant: 'workProjectContainer' }}>
        <h1 sx={{ variant: 'heading', fontWeight: '400' }}>
          My work experience as a full-time &amp; contract Front-End Developer, <br/>
          and some individual project highlights below.
        </h1>
        <h3 sx={{ fontWeight: '400' }}>
          See something you like? Click for more details.
        </h3>
        <h4 sx={{ fontWeight: '400' }}>
          Interested in working together? <Link
          sx={{ variant: 'link.heading', display: 'inline' }}
          onClick={() => useGtag('event', 'click', 'menu', { name: 'say-hello-work' })}
          to={'/contact'}>Say hello.</Link>
        </h4>
        <div>
          {data.allMarkdownRemark.edges.map((item: WorkItemObj) => (
            <RenderWorkItem data={item} key={item.node.frontmatter.title}/>
          ))}
        </div>
      </Container>
    </DefaultLayout>);
};

export default WorksPage;

export const query = graphql`
  query WorkPageTemplateQuery {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }, filter: { frontmatter: { category: { eq: "work" } } }) {
      edges {
        node {
          html
          excerpt
          frontmatter {
            title
            category
            slug
            image {
              childImageSharp {
                sizes(maxWidth: 1080, quality: 100) {
                  ...GatsbyImageSharpSizes_tracedSVG
                }
              }
            }
            projectTitle
            projectClient
            projectAgency
            portfolioCategories
            published
          }
        }
      }
    }
  }
`;
